import React, { Dispatch, Key, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { PotentialCustomerInterface, SubscriberInterface } from '../../../domain/CustomerInfoRecall/CustomerInfoRecallGatewayInterface';
import { SubscriberScopeEnum } from '../../../domain/CustomerInfoRecall/SubscriberScopeEnum';
import PairingTab from './PairingTab';
import { Tab, Tabs } from '../tab/Tab';
import { FolderDetail } from '../../../domain/Folder/Folder';

interface Props {
  dossierId: string
  dossierSummary: FolderDetail|null
  subscribers: {[scope: string]: SubscriberInterface} | null
  potentialCustomers: {[scope: string]:PotentialCustomerInterface[]} | null
  scope: string
  setScope: Dispatch<SetStateAction<string>>
  pairing: {[scope: string]: string|null}
  manualSelection: {[scope: string]: PotentialCustomerInterface|null}
  onModify?: (scope: string, username: string|null) => void
}

const Pairing: React.FC<Props> = ({ dossierId, dossierSummary, subscribers, potentialCustomers, scope, setScope, onModify, pairing, manualSelection }) => {
  const { t } = useTranslation();

  const subscriberTitle = (subscribers?.[SubscriberScopeEnum.USU] || 'mandate' === dossierSummary?.detention_mode) && t('validation.subscriber.bareowner') || t('validation.subscriber.full_property')
  const onTabChange = (index: number, key: Key|null) => {
    if (key) {
      setScope(index === 0 ? SubscriberScopeEnum.NP : SubscriberScopeEnum.USU)
    }
  };

  const npScope = (
    'mandate' !== dossierSummary?.detention_mode ||
    SubscriberScopeEnum.USU !== dossierSummary?.dismemberment_scope)
    ? SubscriberScopeEnum.NP : SubscriberScopeEnum.USU;

  const usuScope = SubscriberScopeEnum.NP === npScope ? SubscriberScopeEnum.USU : SubscriberScopeEnum.NP;

  return (
    <div className="u-mxAuto box-elevations u-pt0 u-pxb">
      <Tabs classes="wrap u-mxAuto" tabButtonClass="link-tab" tabClass="link-tab--2" onTabChange={onTabChange} active={SubscriberScopeEnum.NP === scope ? 0 : 1}>
        {subscribers && (subscribers?.[npScope] || SubscriberScopeEnum.USU === npScope) && (
          <Tab key={`pairing-np`} label={t(`${subscriberTitle}`)}>
            <PairingTab
              dossierId={dossierId}
              subscribers={subscribers}
              potentialCustomers={potentialCustomers ? potentialCustomers[npScope]: null}
              scope={SubscriberScopeEnum.NP}
              subscriberScope={npScope}
              onModify={onModify}
              pairing={pairing}
              manualSelection={manualSelection[SubscriberScopeEnum.NP]}
              allowCreation={dossierSummary?.dismemberment_scope === SubscriberScopeEnum.NP || 'mandate' !== dossierSummary?.detention_mode}
            />
          </Tab>
        )}
        {subscribers && (subscribers?.[SubscriberScopeEnum.USU] || 'mandate' === dossierSummary?.detention_mode) && (
          <Tab key={`pairing-usu`} label={t(`validation.subscriber.usufruct`)}>
            <PairingTab
              dossierId={dossierId}
              subscribers={subscribers}
              potentialCustomers={potentialCustomers ? potentialCustomers[usuScope]: null}
              scope={SubscriberScopeEnum.USU}
              subscriberScope={usuScope}
              onModify={onModify}
              pairing={pairing}
              manualSelection={manualSelection[SubscriberScopeEnum.USU]}
              allowCreation={dossierSummary?.dismemberment_scope === SubscriberScopeEnum.USU || 'mandate' !== dossierSummary?.detention_mode}
            />
          </Tab>
        )}
      </Tabs>
    </div>
  )
};

export default Pairing;
