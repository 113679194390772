import {InputHTMLAttributes, useCallback, useEffect, useState} from "react";
import Modal from "./Modal";
import { useTranslation } from "react-i18next";
import useModal from "./useModal";
import { KYCControlInterface, KYCDocumentInterface } from "../../../domain/Folder/KYCControl";

interface IPreviewModal {
    id: string,
    kyc: KYCControlInterface
    document: KYCDocumentInterface  
    hide?: () => void;
    className?: string;
}


const PreviewKYCModal = ({ id, kyc, document, hide, className } :IPreviewModal) => {
    const {t} = useTranslation()
    const [isPreviewDocument, togglePreviewDocument] = useModal();
    const [modalTitle, setModalTitle] = useState<string>('');
    const [modalContent, setModalContent] = useState<InputHTMLAttributes<string>>();

    const handleHide = useCallback(() => {
        togglePreviewDocument()
        if (hide) {
            hide()
        }
    }, [])

    const moralPerson = (document.person?.siret || document.person?.legalForm || document.person?.legalname) ? (
        <>
            <hr className="col-md-12 u-mxs"/>
            <div className="col-md-6">
                <span className={`u-txt-bold u-txt-underline u-txt-size-s`}>Statut juridique:</span> : <span className={`u-text-size-s`}>{document.person?.legalForm ?? 'N/A'}</span>
            </div>
            <div className="col-md-6">
                <span className={`u-txt-bold u-txt-underline u-txt-size-s`}>Raison sociale:</span> : <span className={`u-text-size-s`}>{document.person?.legalname ?? 'N/A'}</span>
            </div>
            <div className="col-md-6">
                <span className={`u-txt-bold u-txt-underline u-txt-size-s`}>Siret:</span> : <span className={`u-text-size-s`}>{document.person?.siret ?? 'N/A'}</span>
            </div>
        </>
    ) : (
        <></>
    );

    const booleanToString = (value: boolean|null|undefined): string => {
        if (null === value) {
            return 'N/A'
        }
        return value ? 'Oui' : 'Non'
    }
    const statusPerson = (kyc.personStatus) ? (
        <>
            <hr className="col-md-12 u-mxs"/>
            <div className="col-md-6">
                <span className={`u-txt-bold u-txt-underline u-txt-size-s`}>PPE:</span> : <span className={`u-text-size-s`}>{booleanToString(kyc.personStatus.ppe)}</span>
            </div>
            <div className="col-md-6">
                <span className={`u-txt-bold u-txt-underline u-txt-size-s`}>Registre des gels:</span> : <span className={`u-text-size-s`}>{booleanToString(kyc.personStatus.freezeList)}</span>
            </div>
            <div className="col-md-6">
                <span className={`u-txt-bold u-txt-underline u-txt-size-s`}>Sanctions UE:</span> : <span className={`u-text-size-s`}>{booleanToString(kyc.personStatus.UESanction)}</span>
            </div>
            <div className="col-md-6">
                <span className={`u-txt-bold u-txt-underline u-txt-size-s`}>Sanctions Nations Unies:</span> : <span className={`u-text-size-s`}>{booleanToString(kyc.personStatus.NUSanction)}</span>
            </div>
            <div className="col-md-6">
                <span className={`u-txt-bold u-txt-underline u-txt-size-s`}>Sanctions OFAC:</span> : <span className={`u-text-size-s`}>{booleanToString(kyc.personStatus.OFACSanction)}</span>
            </div>
        </>
    ) : (
        <></>
    )


    return (
        <Modal title={t('modal.kyc.title')}
            description={modalTitle}
            customClassName={className ?? `modal`}
            hide={handleHide}
            loading={false}>
            <div className={`flex-container padding`}>
                <div className="col-md-6">
                    <span className={`u-txt-bold u-txt-underline u-txt-size-s`}>Nom</span> : <span className={`u-text-size-s`}>{document.person?.lastname ?? 'N/A'}</span>
                </div>
                <div className="col-md-6">
                    <span className={`u-txt-bold u-txt-underline u-txt-size-s`}>Prénom</span> : <span className={`u-text-size-s`}>{document.person?.firstname ?? 'N/A'}</span>
                </div>
                <div className="col-md-6">
                    <span className={`u-txt-bold u-txt-underline u-txt-size-s`}>Nom d'usage</span> : <span className={`u-text-size-s`}>{document.person?.maidenname ?? 'N/A'}</span>
                </div>
                <div className="col-md-6">
                    <span className={`u-txt-bold u-txt-underline u-txt-size-s`}>Nationalité</span> : <span className={`u-text-size-s`}>{document.person?.nationality ?? 'N/A'}</span>
                </div>
                <div className="col-md-6">
                    <span className={`u-txt-bold u-txt-underline u-txt-size-s`}>Date de naissance</span> : <span className={`u-text-size-s`}>{document.person?.birthDate ?? 'N/A'}</span>
                </div>
                <div className="col-md-6">
                    <span className={`u-txt-bold u-txt-underline u-txt-size-s`}>Lieu de naissance</span> : <span className={`u-text-size-s`}>{document.person?.birthPlace ?? 'N/A'}</span>
                </div>
                <div className="col-md-6">
                    <span className={`u-txt-bold u-txt-underline u-txt-size-s`}>Rue </span> : <span className={`u-text-size-s`}>{document.person?.address?.street ?? 'N/A'}</span>
                </div>
                <div className="col-md-6">
                    <span className={`u-txt-bold u-txt-underline u-txt-size-s`}>Code Postal</span> : <span className={`u-text-size-s`}>{document.person?.address?.zipcode ?? 'N/A'}</span>
                </div>
                <div className="col-md-6">
                    <span className={`u-txt-bold u-txt-underline u-txt-size-s`}>Ville</span> : <span className={`u-text-size-s`}>{document.person?.address?.city ?? 'N/A'}</span>
                </div>
                <div className="col-md-6">
                    <span className={`u-txt-bold u-txt-underline u-txt-size-s`}>Pays</span> : <span className={`u-text-size-s`}>{document.person?.address?.country ?? 'N/A'}</span>
                </div>
                
                {moralPerson}

                {statusPerson}
            </div>
        </Modal>
    )
}

export default PreviewKYCModal;
