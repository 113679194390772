import React, { Dispatch, Key, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReceiptView } from '../../../domain/Receipt/ReceiptView';
import { KYCControlInterface } from '../../../domain/Folder/KYCControl';
import { Tab, Tabs } from '../tab/Tab';
import { SubscriberScopeEnum } from '../../../domain/CustomerInfoRecall/SubscriberScopeEnum';
import ReceiptTab from './ReceipTab';

interface Props {
  dossierId: string
  receipts: { [key: string]: ReceiptView[] };
  kyc: {[scope: string]: KYCControlInterface}|null
  scope: string
  setScope: Dispatch<SetStateAction<string>>
}

const ReceiptList: React.FC<Props> = ({ dossierId, receipts, kyc, scope, setScope }) => {
  const { t } = useTranslation();

  const receiptsByScope = {}
  if (receipts) {
    Object.keys(receipts).map((type, index) => {
      for(let i=0 ; i<receipts[type].length ; i++) {
        const element = receipts[type][i]
        let targetedScope = element.scope
        if (!targetedScope) {
          targetedScope = SubscriberScopeEnum.UNDEFINED
        }
        if (!(targetedScope in receiptsByScope)) {
          receiptsByScope[targetedScope] = {}
        }
        if (!(type in receiptsByScope[targetedScope])) {
          receiptsByScope[targetedScope][type] = []
        }
        receiptsByScope[targetedScope][type].push(element)
      }
    })
  }
  
  const tabs = {}
  if(receiptsByScope[SubscriberScopeEnum.NP]) {
    tabs[SubscriberScopeEnum.NP] = 0
  }
  if(receiptsByScope[SubscriberScopeEnum.NP_COSOUS]) {
    tabs[SubscriberScopeEnum.NP_COSOUS] = Object.keys(tabs).length
  }
  if(receiptsByScope[SubscriberScopeEnum.USU]) {
    tabs[SubscriberScopeEnum.USU] = Object.keys(tabs).length
  }
  if(receiptsByScope[SubscriberScopeEnum.USU_COSOUS]) {
    tabs[SubscriberScopeEnum.USU_COSOUS] = Object.keys(tabs).length
  }
  if(receiptsByScope[SubscriberScopeEnum.UNDEFINED] || !tabs) {
    tabs[SubscriberScopeEnum.UNDEFINED] = Object.keys(tabs).length
  }

  const onTabChange = (index: number, key: Key|null) => {
    if (key && tabs) {
      const keys = Object.keys(tabs)
      for(let i=0; i<keys.length ; i++) {
        if(index === tabs[keys[i]]) {
          setScope(keys[i])
          break
        }
      }
    }
  };

  return (
    <>
    <div className="flex-container">
      <h2 className="help-wrapper u-mbs u-mtl col-md-12">
        {t('validation.receipts')}
      </h2>
    </div>
    <div className="u-mxAuto box-elevations u-pt0 u-px0">
      <Tabs classes="wrap u-mxAuto" tabButtonClass="link-tab" onTabChange={onTabChange} active={tabs[scope] ?? 0}>
        {Object.keys(receiptsByScope).map((key:string)  => {
          return <Tab key={`receipt-${key}`} label={t(`validation.subscriber.${key}`)}>
            <ReceiptTab
              dossierId={dossierId}
              receipts={receiptsByScope[key]}
              kyc={kyc ? kyc[key] : null}
              scope={key}
            />
          </Tab>
        })}
      </Tabs>
    </div>
    </>
  )
};

export default ReceiptList;
