import React from 'react'
import {useTranslation} from 'react-i18next'

import SelectedTickMark from '../../svg/SelectedTickMark';
import MoralPersonLineTable from './MoralPersonLineTable';
import PhysicalPersonLineTable from './PhysicalPersonLineTable';
import { PotentialCustomerInterface } from '../../../../domain/CustomerInfoRecall/CustomerInfoRecallGatewayInterface';

interface Props {
  potentialCustomer: PotentialCustomerInterface;
  onSelectCustomer: (username: string) => void;
  selectedUsername: string|null;
}

const CustomerLineTable: React.FC<Props> = ({potentialCustomer, onSelectCustomer, selectedUsername}) => {
  return (
    <>
      {potentialCustomer.isMoralPerson
        ? <MoralPersonLineTable potentialCustomer={potentialCustomer} selectedUsername={selectedUsername} onSelectCustomer={onSelectCustomer}/>
        : <PhysicalPersonLineTable potentialCustomer={potentialCustomer} selectedUsername={selectedUsername} onSelectCustomer={onSelectCustomer}/>
      }
    </>
  )

}

export default CustomerLineTable
