import { FunctionComponent, useState } from "react"
import { useTranslation } from "react-i18next"

import {ReceiptView} from '../../../domain/Receipt/ReceiptView'
import PreviewReceiptUseCase from '../../../useCase/Receipt/PreviewReceiptUseCase'
import FetchReceiptDetailGateway from '../../../gateway/Receipt/FetchReceiptDetailGateway'
import Converter from '../../util/Converter'
import {downloadFile} from '../../util/DownloadFile'
import IconFactory from "../svg/IconFactory"
import MimeTypeFactory from "../svg/mimetype/MimeTypeFactory"
import PreviewDocumentModal from "../modal/PreviewModal"
import Spinner from "../svg/Spinner"
import ErrorModal from "../modal/ErrorModal"
import { IPreviewFile, PreviewFile } from "../modal/PreviewFile"
import ReceiptDetail from "../../../domain/Receipt/ReceiptDetail"
import { KYCControlInterface, KYCDocumentInterface } from "../../../domain/Folder/KYCControl"
import PreviewKYCModal from "../modal/PreviewKYCModal"

type Props = {
  receipt: ReceiptView
  kyc: KYCControlInterface|null|undefined
  document: KYCDocumentInterface|null
}

const Receipt: FunctionComponent<Props> = ({receipt, kyc, document}) => {
  const {t} = useTranslation()

  const [previewReceipt, setPreviewReceipt] = useState<boolean>(false)
  const [isLoading, setLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string|null>(null)
  const [previewKYC, setPreviewKYC] = useState<boolean>(false)

  const getReceiptFile = (receiptId: string): Promise<ReceiptDetail|null> => {
    return new PreviewReceiptUseCase(new FetchReceiptDetailGateway()).execute(receiptId).then(response => {
        return response
    })
  }
  const downloadReceipt = (receiptToShow: ReceiptView) => () => {
    setLoading(true)
    getReceiptFile(receiptToShow.id).then(response => {
      if (response) {
        downloadFile(response, response.resource)
        setLoading(false)
      } else {
        setLoading(false)
        setErrorMessage(t('ged.download_failed'))
      }
    }).catch((error) => {
      setLoading(false)
      if (error.errors?.length && error.errors[0]?.message?.length>0) {
        setErrorMessage(t(error.errors[0].message))
      } else {
        setErrorMessage(t('ged.download_failed'))
      }
      return null;
    })
  }

  const fetchReceiptFile = (receiptId: string): Promise<IPreviewFile|null> => {
    return getReceiptFile(receiptId).then(response => {
      if (response) {
        return new PreviewFile(
          response.resource,
          response.title,
          response.mimeType
      )
      } else {
        setErrorMessage(t('ged.download_failed'))
        return null
      }
    }).catch((error) => {
      setLoading(false)
      if (error.errors?.length && error.errors[0]?.message?.length>0) {
        setErrorMessage(t(error.errors[0].message))
      } else {
        setErrorMessage(t('ged.download_failed'))
      }
      return null;
    })
  }

  const buttonClassName = isLoading||previewReceipt ? "button-disabled" : "button-enabled"
  return (
    <>
      { errorMessage && <ErrorModal message={errorMessage} onClose={() => setErrorMessage(null)} /> }
      {previewReceipt && <PreviewDocumentModal id={receipt.id} download={fetchReceiptFile} hide={() => setPreviewReceipt(false)}/>}
      {(previewKYC && kyc && document) && <PreviewKYCModal id={`${receipt.id}-kyc`} kyc={kyc} document={document} hide={() => setPreviewKYC(false)}/>}
      <div className={`validation--document`}>
        <div className="validation__label">
          <MimeTypeFactory filename={receipt.title} />
          <span>{receipt.title} {document?.type ? `(${document?.type})`:``}</span>
        </div>
        <div className={`validation__cta`} >
          <div className="pj-container-min-preview pj-figure" title={previewReceipt ? t('button.view-ongoing') : t('button.view')}>
            <div className={`button align-items-end button--small pj-file-min-preview ${buttonClassName}`}
              onClick={() => setPreviewReceipt(true)}>
              {previewReceipt ? <Spinner /> : <IconFactory type={"eye"} className={'pj-preview'}/>}
            </div>
          </div>
        </div>
        <div className={`validation__cta`} >
          <div className="pj-container-min-preview pj-figure" title={isLoading ? t('button.download-ongoing') : t('button.download')}>
            <div className={`button align-items-end button--small pj-file-min-preview ${buttonClassName}`}
              onClick={downloadReceipt(receipt)}>
              {isLoading ? <Spinner /> : <IconFactory type={"download"} className={'pj-preview-fill'}/>}
            </div>
          </div>
        </div>
        <div className={`validation__cta`} >
          <div className="pj-container-min-preview pj-figure" title={document ? t('button.view-kyc') : t('button.kyc-not-available')}>
            { document ? (
            <div className={`button align-items-end validation--receipt button--small pj-file-min-preview ${buttonClassName}`}
              onClick={() => setPreviewKYC(true)}>
                <div className={`validation__label`}>
                  <span>{document?.score ?? 'N/A'}</span>
                </div>
            </div>
            ) : (
              <div className={`button align-items-end validation--receipt button--small pj-file-min-preview button-disabled`}>
                <div className={`validation__label`}>
                  <span>N/A</span>
                </div>
            </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default Receipt
