import React from 'react';
import { useTranslation } from 'react-i18next';

import { SubscriberInterface } from '../../../../domain/CustomerInfoRecall/CustomerInfoRecallGatewayInterface';

interface Props {
  subscriber: SubscriberInterface,
}

const MoralPerson: React.FC<Props> = ({subscriber}) => {
  const {t} = useTranslation()

  const translationKey = subscriber.birthCountry && subscriber.birthCity ? 
    'validation.customer-info-recall-birth-date-with-city-and-country' : (
      subscriber.birthCountry ? 'validation.customer-info-recall-birth-date-with-country' : (
        subscriber.birthCity ? 'validation.customer-info-recall-birth-date-with-city' : 'validation.customer-info-recall-birth-date'
      )
    );

  return (
    <div className="row">
      <div className="col-md-12">
        <h4 className='h6'>Souscripteur :</h4>
        <div className="">
          {subscriber.legalForm ?? ''} {subscriber.companyName ?? ''}<br/>
          {t('validation.represented-by')} {subscriber.fullName ?? `${subscriber.firstName ?? ''} ${subscriber.lastName?? ''}`}
        </div>
        <div className="">
            {(subscriber.birthDay) && t(translationKey, {
              date: subscriber.birthDay,
              city: subscriber.birthCity,
              country: subscriber.birthCountry,
            })}
        </div>
        <div className="">
          {subscriber.email}
        </div>
      </div>
    </div>
  )
  ;
};

export default MoralPerson;
