import CustomerInfoRecallGatewayInterface, { CustomerInformationInterface, PotentialCustomerInterface } from '../../domain/CustomerInfoRecall/CustomerInfoRecallGatewayInterface'
import Caller from '../../domain/Api/Caller'
import envVariable from '../../presentation/util/envVariable'


export default class CustomerInfoRecallGateway implements CustomerInfoRecallGatewayInterface {
  async retrieve(dossierId: string): Promise<CustomerInformationInterface | null> {
    try {
      const datas = await Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/dossier/integration-state/${dossierId}/pairing/customer`, {})
      
      return datas
    } catch {
      return null
    }
  }

  async pair(dossierId: string, username: string|null, scope: string, partnerCode: string|null): Promise<boolean> {
    try {
      await Caller.executePut(`${envVariable('REACT_APP_API_URL')}/dossier/integration-state/${dossierId}/pairing/customer`, {
        code: username,
        scope: scope,
        partner: partnerCode
      })
      return true
    } catch {
      return false
    }
  }

  async search(username: string): Promise<PotentialCustomerInterface|null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/dossier/integration-state/customer/${username}`, {})
    .then((response) => {
      if (response && response.customer) {
        return response.customer
      } else {
        return null;
      }
    })
    .catch(() => {
      return null
    })
  }
}
