import Caller from "../../domain/Api/Caller";
import envVariable from "../../presentation/util/envVariable";
import FolderGatewayInterface from "../../domain/Folder/FolderGatewayInterface";
import {Folder, FolderDetail} from "../../domain/Folder/Folder";
import storage from "../../presentation/util/storage";
import { SignedDocument } from "../../domain/Docusign/SignedDocument";

export default class FetchFolderGateway implements FolderGatewayInterface {
    async createFolder(formId: string, clientId: number): Promise<Folder | null> {

        return Caller.executePost(`${envVariable('REACT_APP_API_URL')}/dossiers`, {
            formId: formId,
            clientId: clientId,
            submittingBlockId: null,
            values: []
        })
            .then(response => {
                if (!response.dossier) {
                    return null
                }

                return new Folder(
                    response.dossier.id,
                    response.dossier.form_id,
                    response.dossier.client_id,
                    response.dossier.advistor_id,
                    response.dossier.last_submitted_block_id,
                    response.dossier.values,
                    response.dossier.direct,
                    response.dossier.receipts,
                    response.dossier.authorized_clients
                )
            })
    }

    async updateFolder(data: Array<string>, BlockId: string): Promise<Folder | null> {
        const values = Object.entries(data).map((value) => {
            if (value) {
                return ({
                    elementId: value[0],
                    value: typeof value[1] === 'boolean' ? String(value[1]) : value[1]
                })
            }
        })

        return Caller.executePut(`${envVariable('REACT_APP_API_URL')}/dossiers/${storage.getFolderId()}/values`, {
            submittingBlockId: BlockId,
            values: values
        })
            .then(response => {
                if (response === undefined) {
                    return null
                }

                return new Folder(
                    response.dossier.id,
                    response.dossier.form_id,
                    response.dossier.client_id,
                    response.dossier.advistor_id,
                    response.dossier.last_submitted_block_id,
                    response.dossier.values,
                    response.dossier.direct,
                    response.dossier.receipts,
                    response.dossier.authorized_clients,
                    response.dossier.status
                )
            })
    }

    async getFolders(currentPage: number, itemPerPage: number, state: string|null, term: string|null, selectedForm: string|null): Promise<{dossiers: FolderDetail[]|null, totalItems: number}> {
        const params = {
          page: currentPage.toString(),
          itemsPerPage: itemPerPage.toString(),
          state: state !== null ? state : '',
          searchTerm: term !== null ? term : '',
          form: selectedForm !== null ? selectedForm : ''
        }

        return await Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/dossiers`, params)
            .then(data => {

                if (!data) {
                    return {dossiers: null, totalItems: 0}
                }

              return {
                dossiers: (data.dossiers).map((dossier) => {
                  return new FolderDetail(
                    dossier.id,
                    dossier.creation_date,
                    dossier.validation_date,
                    dossier.signature_date,
                    dossier.status,
                    dossier.client,
                    dossier.detail,
                    dossier.advisor,
                    dossier.present ?? '',
                    dossier.subscriptionGlobalAmount ?? '',
                    dossier.integrate_state ?? 'ongoing',
                    dossier.form_id,
                    null,
                    null,
                    null
                  )
                }),
                totalItems: data.total_items
              }
            })
    }

    async getFoldersByIntegrationState(currentPage: number, itemPerPage: number, state: string|null, term: string|null, formId: string|null): Promise<{dossiers: FolderDetail[]|null, totalItems: number}> {
        const params = {
          page: currentPage.toString(),
          itemsPerPage: itemPerPage.toString(),
          searchTerm: term !== null ? term : '',
          integrationState: state !== null ? state : '',
          form: formId !== null ? formId : ''
        }

        return await Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/dossiers/integration-state`, params)
            .then(data => {

                if (!data) {
                    return {dossiers: null, totalItems: 0}
                }

              return {
                dossiers: (data.dossiers).map((dossier) => {
                  return new FolderDetail(
                    dossier.id,
                    dossier.creation_date,
                    dossier.validation_date,
                    dossier.signature_date,
                    dossier.status,
                    dossier.client,
                    dossier.detail,
                    dossier.advisor,
                    dossier.present ?? '',
                    dossier.subscriptionGlobalAmount ?? '',
                    dossier.integrate_state ?? 'ongoing',
                    dossier.form_id,
                    null,
                    null,
                    null
                  )
                }),
                totalItems: data.total_items
              }
            })
    }

    async getFolder(folderId: string): Promise<Record<string, unknown> | null> {
        return await Caller.executeGet(
            `${envVariable('REACT_APP_API_URL')}/dossiers/${folderId}`,
            {}
        )
            .then(
                dossier => {
                    if (!dossier) {
                        return null
                    }
                    return dossier
                }
            )
    }

    async updateFolderStatusToCanceled(folderId: string): Promise<Folder> {
        return await Caller.executePut(
            `${envVariable('REACT_APP_API_URL')}/dossiers/${folderId}/status`,
            {status: 'canceled'}
        )
            .then(
                response => {
                    return new Folder(
                        response.dossier.id,
                        response.dossier.form_id,
                        response.dossier.client_id,
                        response.dossier.advistor_id,
                        response.dossier.last_submitted_block_id,
                        response.dossier.values,
                        response.dossier.direct,
                        response.dossier.receipts,
                        response.dossier.authorized_clients,
                        response.dossier.status
                    )
                }
            )
    }

    async downloadSignedFolderDocument(folderId: string): Promise<Blob> {
        return await Caller.executeGetResponseAsBlob(
            `${envVariable('REACT_APP_API_URL')}/dossier/${folderId}/docusign/download`,
            {}
        )
          .then(blob => blob)
          .catch((errors) => Promise.reject(errors))
    }

    async folderDocuSignHistory(folderId: string): Promise<Record<string, unknown> | null> {
        return await Caller.executeGet(
            `${envVariable('REACT_APP_API_URL')}/dossier/${folderId}/docusign/history`,
            {}
        )
        .then(
            response => {
                return {
                    envelopeHistoryData: response.envelopeHistoryData
                }
            }
        ).catch(
            (error) => {
                return Promise.reject(error)
            }
        )
    }

    async retrieveNumberDossiersByState(): Promise<Record<string, number>|null> {

      return await Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/dossiers/retrieve-number-dossiers-by-status`, {})
        .then(data => {

          if (!data) {
            return null
          }

          return data
        })
    }

    async updateDossierIntegrationState(dossierId: string, state: string, reason: string|null): Promise<boolean> {
      const params = {
        integration_state: state
      }

      if (reason) {
        params['reason'] = reason
      }
      return await Caller.executePut(`${envVariable('REACT_APP_API_URL')}/dossiers/integration-state/${dossierId}`, params)
        .then(() => {
          return true;
        }).catch(() => {
          return false
        })
    }

    async retrieveState(dossierId: string): Promise<string|null> {
      return await Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/dossier/integration-state/${dossierId}/state`, {})
        .then(data => {
          if (!data) {
            return null
          }

          return data.state
        })
    }

    async getSummary(dossierId: string): Promise<FolderDetail|null> {
      return await Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/dossier/${dossierId}/summary`, {})
      .then(data => {
          if (!data) {
              return null
          }

          const dossier = data.dossier
          return new FolderDetail(
            dossier.id,
            dossier.creation_date,
            dossier.validation_date,
            dossier.signature_date,
            dossier.status,
            dossier.client,
            dossier.detail,
            dossier.advisor,
            dossier.present ?? '',
            dossier.subscriptionGlobalAmount ?? '',
            dossier.integrate_state ?? 'ongoing',
            dossier.form_id,
            dossier.detention_mode,
            dossier.dismemberment_type,
            dossier.dismemberment_scope,
          )
        }
      )
    }

    async getPairing(dossierId: string): Promise<{[scope: string]: string}|null> {
      return await Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/dossier/integration-state/${dossierId}/pairing`, {})
        .then(data => {
          if (!data) {
            return null
          }

          return data.pairing
        })
    }
}

