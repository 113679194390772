import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Receipt from '../validation/Receipt'
import {ReceiptView} from '../../../domain/Receipt/ReceiptView'
import IconFactory from '../svg/IconFactory'
import FetchFolderGateway from '../../../gateway/Folder/FetchFolderGateway'
import GetDetailAndStoreItUseCase from '../../../useCase/Folder/GetDetailAndStoreItUseCase'
import LocalStorageGateway from '../../../gateway/Form/LocalStorageGateway'
import {ErrorResponse} from '../../../domain/Api/Type'
import ErrorModal from '../modal/ErrorModal';
import { SignedDocument } from '../../../domain/Docusign/SignedDocument';
import SignDocument from '../validation/SignDocument';
import Spinner from '../svg/Spinner';
import { KYCControlInterface, KYCDocumentInterface } from '../../../domain/Folder/KYCControl';
import KYCGateway from '../../../gateway/KYC/KYCGateway';
import GetKYCReportUseCase from '../../../useCase/KYC/GetKYCReportUseCase';
import { SubscriberScopeEnum } from '../../../domain/CustomerInfoRecall/SubscriberScopeEnum';
import { IPreviewFile, PreviewFile } from '../modal/PreviewFile';
import PreviewDocumentModal from '../modal/PreviewModal';
import ReceiptList from './ReceiptList';

interface Props {
  dossierId: string;
  receipts: { [key: string]: ReceiptView[] };
  documents : SignedDocument[]|null;
  kyc: {[scope: string]: KYCControlInterface}|null
}

const ReceiptsValidation: React.FC<Props> = ({dossierId, receipts, documents, kyc}) => {
  const {t} = useTranslation()
  const [isDownloading, setDownloading] = useState<boolean>(false);
  const [previewReport, setPreviewReport] = useState<string|null>(null);
  const [errorMessage, setErrorMessage] = useState<string|null>(null)
  const [scope, setScope] = useState<string>('np')

  const downloadAllDocuments = () => {
    if(isDownloading)
      return
    setDownloading(true)
    const getDetailAndStoreItUseCase = new GetDetailAndStoreItUseCase(new FetchFolderGateway(), new LocalStorageGateway())

    getDetailAndStoreItUseCase?.downloadSignedDocument(dossierId)?.then((response) => {
      if (response !== null) {
        const url = URL.createObjectURL(response)
        const a = document.createElement('a')
        document.body.appendChild(a)
        a.href = url
        a.download = `dossier-${dossierId}.zip`
        a.click()
        window.URL.revokeObjectURL(url)
      }
    })
      .catch((errors: ErrorResponse) => {
        if (errors.errors?.length > 0) {
          setErrorMessage(errors.errors[0].message);
        } else {
          setErrorMessage(t(`docusign.unexpected_error`));
        }
      })
      .finally(() =>
        setDownloading(false)
      )
  }


  const fetchReportFile = (id: string, scope: string): Promise<IPreviewFile|null> => {
    const kycUseCase = new GetKYCReportUseCase(new KYCGateway())
    return kycUseCase.handle(dossierId, scope).then((response) => {
      if (response) {
        return new PreviewFile(
          response.resource,
          response.title,
          response.mimeType
        )
      }
      return null
    })
    .catch((errors: ErrorResponse) => {
      if (errors.errors?.length > 0) {
        setErrorMessage(errors.errors[0].message);
      } else {
        setErrorMessage(t(`kyc.unexpected_error`));
      }
      return null
    })
  }

  const downloadKYCReport = () => {
    if(isDownloading)
      return

  }

  const findResult = (receiptId: string): KYCDocumentInterface|null => {
    if(!kyc) {
      return null;
    }
    for (const [key, value] of Object.entries(kyc)) {
      const doc = value.documents?.filter((item) => { return item.receiptId === receiptId})
      if(doc?.length>0) {
        return doc[0];
      }
    }
    
    return null;
  }
  const getDownloadCaption = isDownloading ? t('button.download-in-progress') : t('button.download-document')
  const downloadDocWidget = (null !== documents && documents?.length > 0) ? (
    <div className="button-preview button-preview--reverse button-preview-download"
         onClick={() => downloadAllDocuments()}
    >
      <IconFactory type="download"/>
      <span className={`u-prs u-pxs`}>{getDownloadCaption}</span>
    </div>
  ) : ( (null !== documents ) ? <Spinner /> : (<span className="u-txt-italic">{t('docusign.documents_not_available')}</span>))

  const getKYCReportCaption = previewReport ? t('button.download-in-progress') : t('button.download-report')
  const downloadKycWidget = (scope: string) => {
    return (null !== kyc && kyc[scope]) ? (
      <div className="button-preview button-preview--reverse button-preview-download u-mbl"
        onClick={() => setPreviewReport(scope)}
      >
        <IconFactory type="eye"/>
        <span className={`u-prs u-pxs`}>{previewReport ? t('button.{{country}}') : t('button.download-report')}</span>
      </div>
    ) : ( 
      (null !== documents ) ? <Spinner /> : (<span className="u-txt-italic u-myl">{t('kyc.report_not_available')}</span>)
    )
  }


  return (
    <>
    {/* 
      { errorMessage && <ErrorModal message={errorMessage} onClose={() => setErrorMessage(null)} /> }
      {previewReport && <PreviewDocumentModal id={`${dossierId}-kyc-np`} download={fetchReportFile} hide={() => setPreviewReport(false)} hideTitle={true}/>}
      */}
      <div
        className={`box-elevations box-elevations--base box-elevations--mb u-mxAuto`}>
        <div className="flex-container">

          <h2 className="help-wrapper u-mbs">
            {t('validation.receipts-signed')}
          </h2>
          {documents?.map((document, index) => (
              <div key={document.id+'-'+index} className="col-md-12">
                <SignDocument folderId={dossierId} document={document}/>
              </div>
            ))}
        </div>
        {downloadDocWidget}

        <ReceiptList dossierId={dossierId} receipts={receipts} kyc={kyc} scope={'np'} setScope={setScope} />
        {/*
        <div className="flex-container">
          <h2 className="help-wrapper u-mbs u-mtl col-md-12">
            {t('validation.receipts')}
          </h2>
          <div className={`u-mbm u-mxs`}>{downloadKycWidget}</div>
          {Object.keys(receipts).map((type, index) => (
            <div key={type} className="col-md-12">
              <h4 className={`${index > 0 ? 'u-mtm' : ''}`}>{type}</h4>
              {receipts[type].map((receipt) => (
                <Receipt key={receipt.id} receipt={receipt} kyc={findResult(receipt.id)}/>
              ))}
            </div>
          ))}
        </div>
        */}
      </div>
    </>
  );
};

export default ReceiptsValidation
