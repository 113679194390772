import {IntegrationStateHistoryInterface} from '../../domain/IntegrationStateHistory/IntegrationStateHistory'
import Caller from '../../domain/Api/Caller'
import envVariable from '../../presentation/util/envVariable'
import KYCGatewayInterface from '../../domain/Folder/KYCGatewayInterface'
import { KYCControl, KYCControlInterface, KYCDocument, KYCPerson, KYCPersonStatus, KYCReportFile, KYCReportFileInterface } from '../../domain/Folder/KYCControl'

export default class KYCGateway implements KYCGatewayInterface {
  async getResults(dossierId: string): Promise<{[scope: string]: KYCControlInterface}|null> {
    try {
      return await Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/dossier/${dossierId}/kyc/results`, {})
        .then(data => {
          if (!data || !data.results) {
            return null
          }

          const v = Object.assign({}, ...data.results.map((item) => ({
            [item.scope]: new KYCControl(
                item.status.success,
                item.status.score,
                item.status?.documents?.map((document) => {
                  return new KYCDocument(
                    document.checked,
                    document.score,
                    document.type,
                    document.person ? new KYCPerson(
                      document.person.firstname,
                      document.person.lastname,
                      document.person.maidenname,
                      document.person.legalname,
                      document.person.address,
                      document.person.nationality,
                      document.person.birth_date,
                      document.person.birth_place,
                      document.person.siret,
                      document.person.legal_form,
                      document.person.email,
                      document.person.phone
                    ) : null,
                    document.expiration_date,
                    document.receipt_id
                  )
                }) ?? null,
                item.status.status ? new KYCPersonStatus(
                  item.status.status.ppe,
                  item.status.status.freeze,
                  item.status.status.uesanction,
                  item.status.status.nuSanction,
                  item.status.status.ofacsanction
                ) : null
              )
            })
          ))
          return v
      })
    } catch(e) {
      return null
    }
  }

  async getReportByFolderId(dossierId: string, scope: string|null): Promise<KYCReportFileInterface|null> {
    return await Caller.executeGet(
      `${envVariable('REACT_APP_API_URL')}/dossier/${dossierId}/kyc/report/${scope ?? 'np'}`, {})
      .then(response => {
        if (!response) {
          return null
        }

        return new KYCReportFile(response.document.id, response.document.resource,response.document.title, response.document.mimetype)
      }).catch((errors) => Promise.reject(errors))
  }
}
