import { ErrorResponse } from '../../domain/Api/Type';
import { KYCControlInterface, KYCReportFileInterface } from '../../domain/Folder/KYCControl'
import KYCGatewayInterface from '../../domain/Folder/KYCGatewayInterface'

export default class GetKYCReportUseCase
{
  constructor(private readonly kycGateway: KYCGatewayInterface) {}

  async handle(dossierId: string, scope: string|null): Promise<KYCReportFileInterface|null> {
    return this.kycGateway.getReportByFolderId(dossierId, scope)
      .then((response) => {
        return response
      })
      .catch((errors: ErrorResponse) => {
        return Promise.reject(errors)
      });
  }
}
