import CustomerInfoRecallGatewayInterface, { CustomerInformationInterface } from '../../domain/CustomerInfoRecall/CustomerInfoRecallGatewayInterface'

export type RetrieveCustomerInfoRecallCommand = {
  dossierId: string
  scope: string
}

export default class RetrieveCustomerInfoRecallUseCase
{
  constructor(private readonly customerInfoRecallGateway: CustomerInfoRecallGatewayInterface) {}

  async handle(retrieveCustomerInfoRecallCommand: RetrieveCustomerInfoRecallCommand): Promise<CustomerInformationInterface|null> {
    const response = await this.customerInfoRecallGateway.retrieve(retrieveCustomerInfoRecallCommand.dossierId)
    if (response) {
      return response
    }
    return null
  }
}
