import CustomerInfoRecallGatewayInterface, { PotentialCustomerInterface } from '../../domain/CustomerInfoRecall/CustomerInfoRecallGatewayInterface'


export default class SearchCustomerInfoRecallUseCase
{
  constructor(private readonly customerInfoRecallGateway: CustomerInfoRecallGatewayInterface) {}

  async handle(username: string): Promise<PotentialCustomerInterface|null> {
    const response = await this.customerInfoRecallGateway.search(username)
    if (response) {
      return response
    }
    return null
  }
}
