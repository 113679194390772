import React, {FC, InputHTMLAttributes} from 'react'
import {IFormFieldProps} from "../../IFormFieldProps";
import Input from "./Input";
import {useTranslation} from 'react-i18next';
import {isValidBIC} from 'ibantools';
import FieldErrorMessage from '../messages/FieldErrorMessage'

interface IProps extends InputHTMLAttributes<HTMLInputElement>, IFormFieldProps, InputProps {
  id?: string
  classes: string,
  value?: string,
  defaultValue?: string
  onChange?: (e: React.FormEvent<HTMLInputElement>) => void,
  clearErrors: (id: string | undefined) => void
}

type InputProps = {
    isUppercase?: boolean
}

const BICCodeInput: FC<IProps> = (
    {
        id,
        classes,
        label,
        onChange,
        value,
        defaultValue,
        register,
        clearErrors,
        error,
        required,
        isUppercase,
        ...rest
    }
) => {

    const handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
      let bic = e.currentTarget.value;
      bic = bic.replace(/\s/g, '');

      if (isUppercase) {
        bic = bic.toUpperCase();
      }

      e.currentTarget.value = bic;

      if (undefined !== onChange) {
        onChange(e);
      }
    }

    return (
        <div className={`${classes} ${rest.readonly && "readonly-element"}`}>
            <div>
                <div className={`bic-code`} title={rest.help}>
                    <div className={`bic-code-content`}>
                      <Input
                        classes={``}
                        register={register}
                        label={label}
                        clearErrors={clearErrors}
                        type={'text'}
                        error={error}
                        id={id}
                        name={id}
                        required={required}
                        readOnly={rest.readonly}
                        value={value}
                        defaultValue={defaultValue}
                        isUppercase={isUppercase}
                        data-skip-error={true}
                        onChange={(e) => handleInputChange(e)}
                        placeholder={rest?.placeholder}
                      />
                    </div>
                </div>
            </div>

        </div>
    )
}

export default BICCodeInput
