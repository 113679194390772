import { KYCControl, KYCControlInterface } from '../../domain/Folder/KYCControl'
import KYCGatewayInterface from '../../domain/Folder/KYCGatewayInterface'

export default class GetKYCResultsUseCase
{
  constructor(private readonly kycGateway: KYCGatewayInterface) {}

  async handle(dossierId: string): Promise<{[scope: string]: KYCControlInterface}|null> {
    return this.kycGateway.getResults(dossierId)
      .then((response) => {
        return response
      })
  }
}
