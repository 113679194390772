import React from 'react';
import { useTranslation } from 'react-i18next';
import { SubscriberInterface } from '../../../../domain/CustomerInfoRecall/CustomerInfoRecallGatewayInterface';

interface Props {
  subscriber: SubscriberInterface,
  cosubscriber: SubscriberInterface,
}

const PhysicalPerson: React.FC<Props> = ({subscriber, cosubscriber}) => {
  const {t} = useTranslation()

  const translationKey = subscriber.birthCountry && subscriber.birthCity ? 
    'validation.customer-info-recall-birth-date-with-city-and-country' : (
      subscriber.birthCountry ? 'validation.customer-info-recall-birth-date-with-country' : (
        subscriber.birthCity ? 'validation.customer-info-recall-birth-date-with-city' : 'validation.customer-info-recall-birth-date'
      )
    );

  return (
    <div className="row">
      <div className={cosubscriber ? "col-md-6" : "col-md-12"}>
        <h4 className='h6'>Souscripteur :</h4>
        <div className="">
          {subscriber.civility}&nbsp;{subscriber.fullName ?? `${subscriber.firstName} ${subscriber.lastName}`}
        </div>
        <div className="">
            {(subscriber.birthDay) && t(translationKey, {
              date: subscriber.birthDay,
              city: subscriber.birthCity,
              country: subscriber.birthCountry,
            })}
        </div>
        <div className="">
          {subscriber.email}
        </div>
      </div>
      {cosubscriber && (
        <div className="col-md-6">
          <h4 className='h6'>Co-souscripteur :</h4>
          <div className="">
            {cosubscriber.civility}&nbsp;{cosubscriber.fullName ?? `${cosubscriber.firstName} ${cosubscriber.lastName}`}
          </div>
          <div className="">
            {(cosubscriber.birthDay || cosubscriber.birthCity) && t(translationKey, {
              date: cosubscriber.birthDay,
              city: cosubscriber.birthCity,
              country: cosubscriber.birthCountry,
            })}
        </div>
          <div className="">
            {cosubscriber.email}
          </div>
        </div>
      )}
    </div>
  );
};

export default PhysicalPerson;
