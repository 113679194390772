import React from 'react'
import {useTranslation} from 'react-i18next'

import SelectedTickMark from '../../svg/SelectedTickMark';
import { PotentialCustomerInterface } from '../../../../domain/CustomerInfoRecall/CustomerInfoRecallGatewayInterface';

interface Props {
  potentialCustomer: PotentialCustomerInterface,
  onSelectCustomer: (username: string) => void;
  selectedUsername: string|null
}

const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

const PhysicalPersonLineTable: React.FC<Props> = ({potentialCustomer, onSelectCustomer, selectedUsername}) => {
  const {t} = useTranslation()

  const translationKey = potentialCustomer.birthCountry && potentialCustomer.birthCity ? 
    'validation.customer-info-recall-birth-date-with-city-and-country' : (
      potentialCustomer.birthCountry ? 'validation.customer-info-recall-birth-date-with-country' : (
        potentialCustomer.birthCity ? 'validation.customer-info-recall-birth-date-with-city' : 'validation.customer-info-recall-birth-date'
      )
    );

  const handleButtonClick = () => {
    onSelectCustomer(potentialCustomer.username ?? '')
  }

  return (
    <>
      <td>
        {potentialCustomer.username}
      </td>
      <td>
        {capitalizeFirstLetter(potentialCustomer.civility ?? '')}
        &nbsp;{capitalizeFirstLetter(potentialCustomer.fullName ?? `${potentialCustomer.firstName ?? ''} ${potentialCustomer.lastName ?? ''}`)}
      </td>
      <td className="">
        {(potentialCustomer.birthDay) && t(translationKey, {
          date: potentialCustomer.birthDay ?? '',
          city: potentialCustomer.birthCity ?? '',
          country: potentialCustomer.birthCountry ?? '',
        })}
      </td>
      <td>
        {potentialCustomer.email ?? ''}
      </td>
      <td className={"form-control-center"}>
        { potentialCustomer?.username && selectedUsername === potentialCustomer?.username  ? <SelectedTickMark color={"var(--ink-1)" } /> : (
        <button type="button"
                className="button button-primary--outline button--medium form-control-center"
                onClick={handleButtonClick}>
          {t('validation.select-customer')}
        </button>
        )}
      </td>
    </>
  )

}

export default PhysicalPersonLineTable
