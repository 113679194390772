import React, {useState, useRef, FC, KeyboardEvent, ChangeEvent, InputHTMLAttributes, FormEvent, useEffect} from 'react'
import {IFormFieldProps} from "../../IFormFieldProps";
import Input from "./Input";
import parse from "html-react-parser";
import {useTranslation} from 'react-i18next';
import FieldErrorMessage from '../messages/FieldErrorMessage';

interface IProps extends InputHTMLAttributes<HTMLInputElement>, IFormFieldProps, InputProps {
    id?: string
    classes: string,
    value?: string,
    defaultValue?: string
    onChange?: (e: FormEvent<HTMLInputElement>) => void,
    clearErrors: (id: string | undefined) => void
}

type InputProps = {
    isUppercase?: boolean
}

const IBANCodeInput: FC<IProps> = (
    {
        id,
        classes,
        label,
        onChange,
        value,
        defaultValue,
        register,
        clearErrors,
        error,
        required,
        isUppercase,
        ...rest
    }
) => {

    const {t} = useTranslation()
    const [valueToSave, setValueToSave] = React.useState<string|undefined>(defaultValue)

    const handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
      let iban = e.currentTarget.value;
      iban = iban.replace(/\s/g, '');

      if (isUppercase) {
        iban = iban.toUpperCase();
      }

      setValueToSave(iban);
      e.currentTarget.value = iban.match(/.{1,4}/g)?.join(' ') || iban;

      if (undefined !== onChange) {
        onChange(e);
      }
    }
    const readOnlyClassName = rest.readonly ? 'readonly-element' : ''
    return (
        <div className={`${classes} ${rest.readonly && "readonly-element"}`}>
            <div>
                <div className={`form-floating iban-code-content relative ${error && 'error'} `} title={rest.help}>
                    <input
                        type="text"
                        id={id}
                        name={id}
                        value={value}
                        defaultValue={defaultValue?.match(/.{1,4}/g)?.join(' ') || defaultValue}
                        onChange={(e) => handleInputChange(e)}
                        className={`form-control ${readOnlyClassName}`}
                        ref={register}
                        placeholder={rest?.placeholder}
                    />
                    <label htmlFor={id}>{parse(label ?? '')}</label>
                    {error?.message && <FieldErrorMessage message={error.message}/>}
                    <Input
                        classes={`u-absolute top-0 left-0`}
                        register={register}
                        clearErrors={clearErrors}
                        type={'hidden'}
                        id={id}
                        name={id}
                        required={required}
                        readOnly={false}
                        value={valueToSave || ''}
                        isUppercase={isUppercase}
                        data-skip-error={true}
                    />
                </div>
            </div>

        </div>
    )
}

export default IBANCodeInput
