import { string } from "yup"

interface KYCPersonInterface {
    firstname: string|null
    lastname: string|null
    maidenname: string|null
    legalname: string|null
    address: {
        street: string|null
        zipcode: string|null
        city: string|null
        country: string|null
    } | null,
    nationality: string|null
    birthDate: string|null,
    birthPlace: string|null
    siret: string|null
    legalForm: string|null
    email: string|null
    phone: string|null
}

interface KYCPersonStatusInterface {
    ppe: boolean|null
    freezeList?: boolean|null
    UESanction?: boolean|null
    NUSanction?: boolean|null
    OFACSanction?: boolean|null
}

interface KYCDocumentInterface {
    checked: boolean
    score: number|null
    type: string|null
    person: KYCPersonInterface|null
    expirationDate: string|null
    receiptId: string|null
}

interface KYCControlInterface {
    success: boolean
    score: number|null
    documents: KYCDocumentInterface[]
    personStatus: KYCPersonStatusInterface|null
}

interface KYCReportFileInterface {
    id: string
    resource: string
    title: string
    mimeType: string|null
}



class KYCPerson implements KYCPersonInterface {
    firstname: string|null
    lastname: string|null
    maidenname: string|null
    legalname: string|null
    address: {
        street: string|null
        zipcode: string|null
        city: string|null
        country: string|null
    }|null
    nationality: string|null
    birthDate: string|null
    birthPlace: string|null
    siret: string|null
    legalForm: string|null
    email: string|null
    phone: string|null

    constructor(
        firstname: string|null,
        lastname: string|null,
        maidenname: string|null,
        legalname: string|null,
        address: {
            street: string|null,
            zipcode: string|null,
            city: string|null,
            country: string|null,
        }|null,
        nationality: string|null,
        birthDate: string|null,
        birthPlace: string|null,
        siret: string|null,
        legalForm: string|null,
        email: string|null,
        phone: string|null,
    ) {
        this.firstname = firstname
        this.lastname = lastname
        this.maidenname = maidenname
        this.legalname = legalname
        this.address = address
        this.nationality = nationality
        this.birthDate = birthDate
        this.birthPlace = birthPlace
        this.siret = siret
        this.legalForm = legalForm
        this.email = email
        this.phone = phone
    }
}

class KYCPersonStatus implements KYCPersonStatusInterface {
    ppe: boolean|null
    freezeList?: boolean|null
    UESanction?: boolean|null
    NUSanction?: boolean|null
    OFACSanction?: boolean|null

    constructor(
        ppe: boolean|null,
        freezeList?: boolean|null,
        UESanction?: boolean|null,
        NUSanction?: boolean|null,
        OFACSanction?: boolean|null
    ) {
        this.ppe = ppe
        this.freezeList = freezeList
        this.UESanction = UESanction
        this.NUSanction = NUSanction
        this.OFACSanction = OFACSanction
    }
}

class KYCDocument implements KYCDocumentInterface {
    checked: boolean
    score: number|null
    type: string|null
    person: KYCPersonInterface|null
    expirationDate: string|null
    receiptId: string|null

    constructor(
        checked: boolean,
        score: number|null,
        type: string|null,
        person: KYCPersonInterface|null,
        expirationDate: string|null,
        receiptId: string|null,
    ) {
        this.checked = checked
        this.score = score
        this.type = type
        this.person = person
        this.expirationDate = expirationDate
        this.receiptId = receiptId
    }
}

class KYCControl implements KYCControlInterface {
    success: boolean
    score: number|null
    documents: KYCDocumentInterface[]
    personStatus: KYCPersonStatusInterface|null

    constructor(
        success: boolean,
        score: number|null,
        documents: KYCDocumentInterface[],
        personStatus: KYCPersonStatusInterface|null
    ) {
        this.success = success
        this.score = score
        this.documents = documents
        this.personStatus = personStatus
    }
}


class KYCReportFile implements KYCReportFileInterface {
    id: string
    resource: string
    title: string
    mimeType: string|null

    constructor(id: string, resource: string, title: string, mimeType: string|null) {
        this.id = id
        this.resource = resource
        this.title = title
        this.mimeType = mimeType
    }
}

export type {
    KYCPersonInterface,
    KYCPersonStatusInterface,
    KYCDocumentInterface,
    KYCControlInterface,
    KYCReportFileInterface
}

export {
    KYCPerson,
    KYCPersonStatus,
    KYCDocument,
    KYCControl,
    KYCReportFile
}